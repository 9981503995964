.Header{
    margin-left: 1em;
}


.About{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Selfie{
    width: 60vw;
}

.bio{
    width: 60vw
}

.Resume{
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}